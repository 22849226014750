input[type="file"] {
  display: none;
}

input[type="range"] {
  -webkit-appearance: none; /* For Chrome, Safari, and Edge */
  -moz-appearance: none;    /* For Firefox */
  appearance: none;         /* Standard */
  background: none;         /* Optional: removes default background */
}

/* Style for WebKit browsers */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 0; /* Removes the thumb */
  width: 0;
  background: transparent; /* Makes it invisible */
}

/* Style for Firefox */
input[type="range"]::-moz-range-thumb {
  appearance: none;
  height: 0; /* Removes the thumb */
  width: 0;
  background: transparent; /* Makes it invisible */
}

/* Optional: Style the track */
/* input[type="range"]::-webkit-slider-runnable-track {
  height: 4px; 
  background: linear-gradient(to right, #16a34a 40%, #e5e7eb 40%);
}

input[type="range"]::-moz-range-track {
  height: 4px;
  background: linear-gradient(to right, #16a34a 40%, #e5e7eb 40%);
} */