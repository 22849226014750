@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
html,
body,
#root,
.app,
body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

/* .css-yps5bw .avater-image {
  aspect-ratio: 3/4;
  mix-blend-mode: plus-lighter;
} */

::-webkit-scrollbar {
  width: 5px;
}
.sidebar ::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.custom-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.custom-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.react-hot-toast {
  z-index: 1050; /* Ensure it's higher than the dialog's z-index */
}


@tailwind base;
@tailwind components;
@tailwind utilities;
