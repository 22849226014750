.live-chat {
  background: url("../../../public/assets/liveChatBG.webp");
  background-repeat: repeat; /* Ensures the background image repeats */
  background-size: 350px;
}
/* .live-chat {
  background: url("../../../public/assets/bgChat.png");
  background-repeat: repeat;
  z-index: 10;
  background-color: #f1e9d3;
  /* background-size: cover; */
/* background-position: center; */
/* } 

/* dork mode looking scrollbar */
.customScroller ::-webkit-scrollbar {
  width: 7px;
}

.customScroller ::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-radius: 5px;
}

.customScroller ::-webkit-scrollbar-thumb {
  background: #888888d4;
  border-radius: 5px;
}

.customScroller ::-webkit-scrollbar-thumb:hover {
  background: #888888;
}

/* SHAKE ANIMATION ON HORIZONTAL DIRECTION */
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0.2s both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
