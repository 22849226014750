.phone-input-container1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  /* Adjust as needed */
}

.phone-input-container1 label {
  margin-bottom: 8px;
  font-weight: bold;
}

.phone-input-container1 .PhoneInput {
  width: 100%;
  margin-top: 15px;
}

.phone-input-container1 .PhoneInputInput {
  border: 1.5px solid #f1f3f4;
  border-radius: 0 8px 8px 0;
  padding: 10px;
  /* width: calc(100% - 56px); Adjust width to accommodate the flag dropdown */
  width: 45%;
  box-sizing: border-box;
  background: #f0f0f0;
  font-size: 16px;
  height: 3rem;
  /* Adjust to match other inputs */
}

.phone-input-container1 .PhoneInputInput:focus {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / 0.2));
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 2px;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 0.2));
}

.phone-input-container1 .PhoneInputCountry {
  border: 1.5px solid;
  border-color: var(--fallback-bc, oklch(var(--bc) / 0.2));
  border-radius: 8px 0 0 8px;
  background-color: #f0f0f0;
  width: 70px;

  height: 3rem;
  /* Adjust to match other inputs */
}

.phone-input-container1 .PhoneInputCountrySelect {
  border: none;
  background: none;
}

.phone-input-container1 .PhoneInputCountryIcon {
  margin-right: 9px;
  margin-left: 17px;
}

.templatebuttons {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px 0px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 0px 10px 5 var(--tw-shadow-color),
    101 10px -28px 151px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 35px;
  width: 100%;
  /* margin-top: 8px; */
  /* border-radius: 3px; */
  text-align: center;
  justify-content: center;
  background-color: white;
  color: #3663EB;
  font: large;
  border-top: rgba(209, 208, 208, 0.979) solid 0.5px ;
    
}