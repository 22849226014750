.react-flow__attribution {
  display: none;
}

.panal1 {
  margin: 0 !important;
}

.panal2 {
  margin-top: 65px !important;
}

.text-updater-node label {
  display: block;
  color: #777;
  font-size: 12px;
}
