.phone-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  /* Adjust as needed */
}

.phone-input-container label {
  margin-bottom: 8px;
  font-weight: bold;
}

.phone-input-container .PhoneInput {
  width: 100%;
}

.phone-input-container .PhoneInputInput {
  border: 1.5px solid #f1f3f4;
  border-radius: 0 8px 8px 0;
  padding: 10px;
  width: calc(100% - 56px);
  /* Adjust width to accommodate the flag dropdown */
  box-sizing: border-box;
  background: white;
  font-size: 16px;
  height: 2rem;
  /* Adjust to match other inputs */
}

.phone-input-container .PhoneInputInput:focus {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / 0.2));
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 2px;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 0.2));
}

.phone-input-container .PhoneInputCountry {
  border: 1.5px solid;
  border-color: var(--fallback-bc, oklch(var(--bc) / 0.2));
  border-radius: 8px 0 0 8px;
  background-color: white;
  width: 70px;

  height: 2rem;
  /* Adjust to match other inputs */
}

.phone-input-container .PhoneInputCountrySelect {
  border: none;
  background: none;
}

.phone-input-container .PhoneInputCountryIcon {
  margin-right: 9px;
  margin-left: 17px;
}